<template>
  <div class="digital-signage-layout">
    <component
      :is="signageLayout"
      :category="category"
      v-if="childCategories"
      :child-categories="childCategories">
    </component>
  </div>
</template>

<script>
  import {markRaw, defineAsyncComponent} from 'vue'

  export default {
    props: [
      'category'
    ],
    computed: {
      signageLayout() {
        let layout = this.$helper.findAttributeValue(this.category, 'Digital Signage Template')
        if (layout) {
          switch (layout) {
            case 'Nine':
              return markRaw(defineAsyncComponent(() => import('../digital-signage/NineLayout')))
            case 'Single':
              return markRaw(defineAsyncComponent(() => import('../digital-signage/SingleLayout')))
            case '6 Left':
              return markRaw(defineAsyncComponent(() => import('../digital-signage/SixLeftLayout')))
            case '6 Right':
              return markRaw(defineAsyncComponent(() => import('../digital-signage/SixRightLayout')))
          }
        }
      },
    },
    data() {
      return {
        childCategories: null,
      }
    },
    async created() {
      if (this.category) {
        this.childCategories = await this.$api.findCategories(this.category.id)
      }
    },
    inject: [
      '$helper',
      '$api',
    ]
  }
</script>

<style lang="scss" scoped>
  .digital-signage-layout {
    @apply
      w-full
      h-full;
  }
</style>